import React from "react";
import { Link } from "gatsby";
import Page from "../Page";
import { graphql } from "gatsby";

export default function QuizPage({ data }) {
  const city = data.citiesJson;
  const pageTitle = "Hitta blombutiker i " + city.title
  return (
    <Page title={pageTitle}>
       <div className="container max-w-screen-xl px-5 py-6 mx-auto ">
          <br />
          <h1 className="font-bold text-4xl md:text-5xl text-gray-900 leading-tight mb-4">{pageTitle}</h1>
          <p className="font-normal text-gray-800 mb-6">Nedan hittar du en lista med addresser och kontaktinformation till olika blombutiker som ligger i {city.title}.</p>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
            {city.shops.map((shop,index) => {
              return <div className="block p-6 bg-white rounded-lg border border-gray-200 shadow-md ">
              <h2 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">{shop.name}</h2>
              <p className="font-normal text-gray-700">{shop.address}</p>
              <p className="font-normal text-gray-700 mt-2"><strong>Telefon</strong>: <a href={"tel:"+shop.phone}>{shop.phone}</a><span></span></p>
              <Link to={shop.urlString} class="text-white bg-emerald-600 hover:bg-emerald-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-2 mt-3 text-center inline-flex items-center" href="#">
                Läs mer →
              </Link>
          </div>
            })}   
        </div>
        </div>
    </Page>
  );
}

export const query = graphql`
  query QuizQuery($slug: String!) {
    citiesJson(url: { eq: $slug }) {
      id
      title
      shops {
        name
        address
        phone
        urlString
      }
    }
  }
`;